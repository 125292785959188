import VueCookies from 'vue-cookies'
import Session from './session.js'
import RequestApi from '@grantstreet/request-api'
import type { XOR } from '@grantstreet/typescript'
import { AsyncComponentLoader, Component } from 'vue'

type AsyncComponentResolveResult = Awaited<ReturnType<AsyncComponentLoader<Component>>>

export type LogData = Record<string, string>

export type LogMeta = {
  appUser: string
  defaultClient: string
  defaultSite?: string
}

export type LogUtils = XOR<{
  logData: (data: LogData) => Promise<unknown>
  isDiagnostic?: false
}, {
  logData: ({
    diagnostics,
    site,
  }: {
    diagnostics: LogData
    site: string
  }) => Promise<unknown>
  isDiagnostic: true
}>

/**
 * Logs either requests or diagnostics to kibana.
 *
 * Depends on VueCookies being installed. Tightly coupled with the architecture
 * of GovHub/PSC ecosystem. This is separated out to avoid duplication and
 * drift; logging is too important to risk.
 */
export const log = (
  data: LogData,
  {
    appUser,
    defaultClient,
    defaultSite = 'unknown',
  }: LogMeta,
  {
    logData,
    isDiagnostic,
  } : LogUtils,
) => {
  data = {
    // @ts-expect-error Ignoring typescript error because VueCookies is typed
    // wrong. See: https://github.com/cmp-cc/vue-cookies/issues/76
    'client_ip': VueCookies.get('client_ip'),

    // XXX: Looks like client_host is never set???

    // @ts-expect-error Ignoring typescript error because VueCookies is typed
    // wrong. See: https://github.com/cmp-cc/vue-cookies/issues/76
    'client_host': VueCookies.get('client_host'),
    'http_session': Session.id,
    'correlation_id': `+${Session.id}`,
    'user_agent': navigator.userAgent,
    'app_user': appUser,
    ...data,
  }

  if (!isDiagnostic) {
    return logData(data)
  }
  // If a client or site are passed, those will be logged to Kibana; if not
  // then the current config client and site will be used. This is so that the
  // client and site can still be logged if this is called before the config
  // has been loaded.

  const client = data.client || defaultClient
  let site = data.site || defaultSite

  if (client) {
    site = `${client}.${site}`
  }

  // This is a better way to remove props than the delete operator.
  // See psc-js/utils/objects.js for more.
  const { client: c, site: s, ...sanitized } = data

  return logData({
    site,
    diagnostics: sanitized,
  })
}

/**
 * Creates a function that will log to kibana when called.
 *
 * Depends on VueCookies being installed.
 *
 * This is a convenience method to use in vuex stores and other places where it
 * we don't want to require the log function each time.
 */
export const logHelperFactory = (logUtils: LogUtils) => (
  {
    data = {},
    ...meta
  }: {
    data?: LogData
  } & LogMeta,
) => log(data, meta, logUtils)

/**
 * Sends data for our dynamic imports to kibana's request logging.
 *
 * Since we don't have access to the generated urls that are being fetched
 * during dynamic imports we log meta data about the fetched resource in the url
 * field instead. If a file path is passed it will be stripped down to the
 * filename for brevity and obfuscation. You can instead pass a resource string
 * which will not be modified.
 */
export const logDynamicImport = (args: XOR<{
  path: string
}, {
  resource: string
}> & {
  app?: string
  status: 'failure' | 'success'
}) => {
  let resource: string
  if (args.resource === undefined) {
    resource = args.path.match(/.*\/([^/.#?]+)(?:[/.#?].*)?$/i)?.[1] || 'unknown'
  }
  else {
    resource = args.resource
  }

  return log(
    {
      url: `dynamic-import:${args.status}:${resource}`,
      referer: document?.referrer,
    },
    {
      appUser: 'unknown',
      defaultClient: 'unknown',
    },
    {
      logData: (data: LogData) => new RequestApi({
        app: args.app,
        // This is a no-op because we're going catch and handle this else in the
        // parent
        exceptionLogger: () => {},
      }).logRequest(data),
      isDiagnostic: false,
    },
  )
}

/**
 * A factory function so you don't have to pass the app string every time.
 */
export const logDynamicImportFactory = (app: string) =>
  (args: Parameters<typeof logDynamicImport>[0] & { app?: never }) =>
    logDynamicImport({
      app,
      ...args,
    })

export type DynamicImportHandlerUtils = {
  app: string
  logException: (...args: [Error, ...Array<unknown>]) => unknown
}

/**
 * Adds kibana and sentry logging for dynamic imports. You should probably use
 * this any time a dynamic import is done.
 */
export const handleDynamicImport = <T = AsyncComponentResolveResult>(
  pathOrResource: string,
  importPromise: Promise<T>,
  {
    app,
    logException,
  }: DynamicImportHandlerUtils,
) => {
  // Because of the way webpack parses and replaces dynamic imports we can't use
  // a variable for the full import path. (And want to use as few variables in
  // the paths as possible. Look up the webpack docs for dynamic imports for
  // more.) Magic comments also have to be written in the consuming scope. So
  // the import has to be done in the consuming scope and the promise passed in.
  // That also means the consumer will have to write the same path string twice
  // if they are passing a file path here. I know that's obnoxious but at this
  // time there's not a way around it.

  // If the string has non-trailing slashes treat it as a path
  const logData = /\/[^/.#?]+/.test(pathOrResource)
    ? { path: pathOrResource, app }
    : { resource: pathOrResource, app }

  // Log successes
  importPromise.then(() => {
    logDynamicImport({ ...logData, status: 'success' })
      .catch(logException)
    // If the import fails log to kibana and sentry
  }).catch(error => {
    logDynamicImport({ ...logData, status: 'failure' })
      .catch(logException)
    logException(error)
  })

  return importPromise
}

/**
 * Returns a handler that accepts the resource or path data to log and a promise
 * from a dynamic import.
 */
export const dynamicImportHandlerFactory = (utils: DynamicImportHandlerUtils) =>
  <T = AsyncComponentResolveResult>(pathOrResource: string, importPromise: Promise<T>) =>
    handleDynamicImport<T>(pathOrResource, importPromise, utils)
