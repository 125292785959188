<template>
  <CheckoutWrapper
    ref="checkoutWrapper"
    :hide-cancel-url-button="hideCancelUrlButton"
    :show-cancel-emit-button="showCancelEmitButton"
    :init-failure="initFailure"
    :is-redirect="isRedirect"
    data-test="checkout-wrapper"
    @cancel-cart="$emit('cancel-cart')"
  >
    <Alert
      v-if="expirationWarning"
      :variant="cart.expired ? 'danger' : 'warning'"
      :dismissible="false"
      :show-icon="true"
      class="cart-expiration-alert row m-1"
    >
      <div>
        {{ expirationWarning }}
        <template v-if="cart.expired">
          <a
            v-if="returnUrl"
            :href="returnUrl"
          >
            {{ $t('cart.expired.return.text') }}
          </a>
          <span v-else>
            {{ $t('cart.expired.return.text') }}
          </span>
        </template>
      </div>
    </Alert>

    <Alert
      v-if="prepopulateCartWarnings.length"
      data-test="prepopulate-cart-warnings"
      variant="warning"
      :dismissible="true"
      :show-icon="false"
      @dismissed="setPrepopulateCartWarnings([])"
    >
      <div>
        <div
          v-for="(warning, index) of prepopulateCartWarnings"
          :key="index"
        >
          <div>
            <strong>{{ $t(warning.localizedHeader.key, warning.localizedHeader.data) }}</strong>
          </div>
          <ul>
            <div
              v-if="warning.message"
              v-dompurify-html="warning.message"
            />
            <div v-else-if="warning.localizedMessage">{{ $t(warning.localizedMessage.key) }}</div>
          </ul>
        </div>
      </div>
    </Alert>

    <EmptyCart v-if="!items.length" />

    <div v-else>

      <!-- Make sure to check that schedpay is enabled -->
      <Cart
        ref="cart"
        view="selection"
        :allows-autopay="canEnrollInAutopay"
        :tender-type="prospectiveTenderType"
        :show-delivery-option-description="showDeliveryOptionDescription"
        :selected-tender-supports-autopay="selectedTenderSupportsAutopay"
        @log-in="handleLogin($event)"
      />
      <DonationsLoader
        component="Donations"
        :cart="cart"
        :payables-adaptor="rexPayablesAdaptor"
        :add-to-cart="(items) => addToCart({items})"
        :remove-from-cart="removeFromCart"
      />

      <section
        v-if="shouldCollectDelivery"
        :aria-label="$t('delivery.delivery')"
        class="bg-white rounded-xl p-4 mb-4"
      >

        <b-overlay
          :show="$wait.is('submitting') || $wait.is('modifying.*')"
          rounded="sm"
          variant="white"
          opacity="0.65"
        >
          <template #overlay>
            <div class="py-4 text-center">
              <div class="spinner spinner-large" />
            </div>
          </template>

          <DeliveryMethodLoader
            ref="delivery"
            component="DeliveryMethod"
            :billing-address="selectedTenderData ? {
              ...selectedTenderData.billingAddress,
              name: selectedTenderData.userName,
            } : undefined"
            limit-addresses
            :cart-items-allow-pick-up="cartItemsAllowPickUp"
            :delivery-option="deliveryOption"
            :change-address="changeAddress"
            :payables="itemPayables"
            :office-location-payables-adaptor="rexPayablesAdaptor"
            :require-permanent-address-change="requirePermanentAddressChange"
            @select="submitCart"
            @delivery="saveChosenAddress"
            @select-delivery-option="switchDeliveryOption"
            @set-change-address="setChangeAddress"
          />
        </b-overlay>
      </section>

      <section
        v-if="displayEwallet"
        :aria-label="$t('checkout.payment')"
        class="bg-white rounded-xl p-4 mb-2 mb-md-3"
      >
        <h2>{{ $t('checkout.payment') }}</h2>
        <eWalletWrapper
          ref="ewallet"
          :always-get-warehouse-token="shouldAuthorizeTendersBeforeCheckout"
          :always-get-warehouse-token-for-banks="shouldAuthorizeTendersBeforeCheckout"
          :always-save-tender="shouldSaveTenders"
          :auto-pay-selected="autoPaySelected"
          :enabled="$refs.cart && !$refs.cart.hasAmountError"
          :convenience-fees="displayFormattedFees"
          :translated-fee-keys="translatedFeeKeys()"
          :default-billing-address="defaultBillingAddress"
          :paypal-config="paypalConfig"
          :apple-pay-config="applePayConfig"
          :google-pay-config="googlePayConfig"
          :force-save-method="forceSaveMethod"
          :show-save-checkbox="showSaveCheckbox"
          :restricted-payable-methods="restrictedPayableMethods"
          :disabled-tenders-message="disabledTendersMessage"
          :disabled-tenders-tooltips="disabledTendersTooltips"
          :is-two-pass-authorization="isTwoPassAuthorization"
          :before-submit="beforeEwalletSubmit"
          :get-updated-fee="getUpdatedFee"
          :submit-callback="showConfirmation"
          :client-title="clientTitle"
          :cart-subtotal="cart.subtotal"
          :submit-button-text="$t('review_button')"
          :cart-has-delayed-payments="cart.hasDelayedItems"
          :is-mixed-rex-cart="isMixedRExCart"
          :hide-fee-message="hideFeeMessage"
          :use-cost-linked-pricing="useCostLinkedPricing"
          @phone-update="phone => profileChanges.phone = phone"
          @contact-preference-update="preference => profileChanges.contactPreference = preference"
          @validation-error="errors => $store.dispatch('PayHub/logDiagnostics', { errors: errors.join('\n') })"
          @tender-type-changed="type => prospectiveTenderType = type"
          @tender-form-type-changed="type => selectedTenderFormType = type"
          @selected-data-changed="updateSelectedTenderData"
          @extra-fields-update="data => extraFields = data"
        >

          <template #additional-checkboxes>
            <ReminderCheckbox
              v-if="showReminderCheckbox"
              class="position-relative ml-sm-5 w-100 mt-2"
              @change-preference="optIn => rexReceiveReminders = optIn"
            />
          </template>
        </eWalletWrapper>

      </section>

      <Alert
        v-if="checkoutError"
        variant="danger"
        icon="alert-exclamation-triangle"
        icon-color="#63171d"
        class="mt-2 mt-md-3"
        @dismissed="checkoutError = ''"
      >

        <span v-dompurify-html="checkoutError" />

      </Alert>

      <!-- Make sure to check that schedpay is enabled -->
      <SchedPayLoader
        v-show="canEnrollInAutopay && termsData"
        id="autopay-terms"
        component="AutopayTermsModal"
        :grouped-schedules="termsData"
        :user-email="(paymentData && paymentData.extraFields && paymentData.extraFields.email) ||
          user.email"
        :user-name="user.name"
        @hide="$wait.end('submitting')"
        @submit="submitWithTerms"
      />

    </div>

    <RenewalAutopayModal
      @hidden="renewalAutopayModalHidden"
      @accepted="acceptRenewalAutopayModal"
      @log-in="handleLogin($event)"
    />

    <b-modal
      :visible="showUpdateCartModal"
      centered
      no-close-on-esc
      no-close-on-backdrop
      ok-only
      hide-header-close
      :hide-header="!updateCartModalError"
      :hide-footer="!updateCartModalError"
      data-test="update-cart-modal"
    >
      <div v-if="!updateCartModalError">
        <div class="row no-gutters align-items-center justify-content-center py-2">
          <div class="col-auto mr-2">
            <LoadingSpinner />
          </div>
          <div class="col-auto">
            {{ updateCartModalMessage }}
          </div>
        </div>
      </div>
      <div v-else>
        {{ updateCartModalError }}
      </div>
    </b-modal>
  </CheckoutWrapper>
</template>

<script>
import SchedPayLoader from '@grantstreet/schedpay-vue/src/SchedPayLoader.vue'
import Cart from '@grantstreet/cart-vue/src/components/Cart.vue'
import EmptyCart from './EmptyCart.vue'
import eWalletWrapper from './eWalletWrapper.vue'
import CheckoutWrapper from './CheckoutWrapper.vue'
import errorMixin from '@grantstreet/psc-vue/utils/errorMixin.js'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { translateOrList, truncatedTranslateOrList } from '@grantstreet/psc-vue/utils/i18n.ts'
import { isInIframe } from '@grantstreet/psc-js/utils/iframe.js'
import { sentryException } from '../sentry.js'
import { useEWalletHelpers } from '../e-wallet-helpers.js'
import { GroupPayablesWithFrequencies } from '@grantstreet/schedpay-vue/src/models/Frequencies.js'
import cloneDeep from 'lodash/cloneDeep.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import { DonationsLoader } from '@grantstreet/donations'
import {
  DeliveryMethodLoader,
  addAddress,
  updateLastUsedAddress,
  resetTempAddresses,
} from '@grantstreet/delivery-method'
import ReminderCheckbox from './ReminderCheckbox.vue'
import LoadingSpinner from '@grantstreet/loaders-vue/LoadingSpinner.vue'
import { friendlyToUglyBrands } from '@grantstreet/psc-js/utils/cards.js'
import { isProd, GSG_ENVIRONMENT } from '@grantstreet/psc-environment'
import { parseNumber, decimalFormat, displayFormat } from '@grantstreet/psc-js/utils/numbers.js'
import { mapConfigGetters, mapConfigState } from '@grantstreet/psc-config'
import { isNavigationFailure } from 'vue-router'
import { pushRouteWithState } from '@grantstreet/psc-js/utils/routing.js'
import { useGsgUser } from '@grantstreet/user'
import checkOutMixin from '@grantstreet/cart-vue/src/mixins/CheckOutFunctions.js'
import RenewalAutopayModal from '@grantstreet/schedpay-vue/src/components/RenewalAutopayModal.vue'
import { useLoginHelpers } from '../login-helpers.js'

export default {
  emits: ['cancel-cart', 'payment-complete'],
  components: {
    eWalletWrapper,
    Cart,
    CheckoutWrapper,
    EmptyCart,
    SchedPayLoader,
    Alert,
    DonationsLoader,
    DeliveryMethodLoader,
    ReminderCheckbox,
    LoadingSpinner,
    RenewalAutopayModal,
  },

  mixins: [
    checkOutMixin,
    errorMixin,
    scrollToMixin,
  ],

  props: {
    hideCancelUrlButton: {
      type: Boolean,
      default: false,
    },
    showCancelEmitButton: {
      type: Boolean,
      default: false,
    },
    // Was this a redirect? If so we display custom loading+error messages.
    isRedirect: {
      type: Boolean,
      default: false,
    },
    tenderValidationError: {
      type: String,
      default: '',
    },
    tenderTypeForValidationError: {
      type: String,
      default: '',
    },
  },

  setup: () => ({
    user: useGsgUser().user,
    handleLogin: useLoginHelpers().handleLogin,
  }),

  data () {
    return {
      serviceError: null,
      selectedTenderFormType: '',
      prospectiveTenderType: '',
      selectedTenderData: {},
      extraFields: {},
      termsData: null,
      paymentData: null,
      // Was there a failure initializing the cart?
      initFailure: false,
      // Used for PayPal info validation
      shouldIncludeContact: false,
      shouldShowCheckbox: false,
      expirationWarning: null,
      checkoutError: '',
      deliveryEvents: {},
      payablesEvents: {},
      /**
       * Changes to the user's profile information that will be
       * committed upon submit.
       */
      profileChanges: {},

      // User's preference for REx renewal reminders - default opt in
      rexReceiveReminders: true,
      forceHideReminderCheckbox: false,

      // Error trying to change a payable in the cart while on the checkout page
      updateCartModalMessage: '',
      updateCartModalError: '',

      // Should cart item description include a description of the delivery option state
      showDeliveryOptionDescription: false,
      showUpdateCartModal: false,

      // When payables are changed in the cart, we will wait half of a second to
      // receive other payable change events before applying those changes to
      // the cart.
      pendingPayableChanges: [],
      // When payableChangeTimeout is not 0, there is a pending timeout to
      // process the pending payable changes.
      payableChangeTimeout: 0,
    }
  },

  beforeRouteLeave (to, from, next) {
    this.checkoutError = ''
    next()
  },

  computed: {
    showReminderCheckbox () {
      return !this.forceHideReminderCheckbox && this.config.renewexpress?.enableEreminderCheckbox && this.hasRExItems
    },

    returnUrl () {
      return (this.urls && this.urls.return) ||
        this.config.payHub.searchPageUrl
    },

    allowedCardBrands () {
      return Object.keys(friendlyToUglyBrands).filter(brand => !this.disabledCardBrands.includes(brand)).map(brand => friendlyToUglyBrands[brand])
    },

    paypalConfig () {
      if (
        // Default to on, but disable if explicitly disabled in LD.
        this.flags[`use-paypal.${this.config.client}-${this.config.site}`] === false
        // There's also a separate Site Setting, enforced elsewhere.
      ) {
        return null
      }

      const api = this.$store.getters['API/cart']
      // Will be filled in with the checkout response from cart
      let payment
      return {
        // The client id can be hard-coded, but it will need to be a different
        // value in production.
        clientId: isProd()
          ? 'AUTp5MoE8ZgOM76zrw4xtx04KDu_9VbDx_VuNWPF67pibWV02QFTD3_pvxZuCDUCnkY9NZzkesz4Rj9N'
          : 'AVtpKFDgF37amCEyEotzzYpwX_aBXyyGu0aSTKRoHBU_iR-RfAlJFjVQfP4BZWp7hTo4DBgpb9bmIGjZ',

        // Use only if venmo is enabled for the site and the cart doesn't have multiple item categories\
        // We also need to disable Venmo in the checkout widget until PayPal fixes the issue where rendering the Venmo button
        // is not supported in an iframe.
        // Delayed payments are also not supported for venmo.
        useVenmo: this.flags[`use-venmo.${this.config.client}-${this.config.site}`] &&
          !this.hasMultipleItemCategories && !isInIframe() &&
          !this.cart.hasDelayedItems,

        merchantIds: this.merchantIDs,

        createOrder: async (data, actions, tender) => {
          if (!this.readyToSubmit() || !await this.beforeEwalletSubmit({ tender })) {
            throw new Error('Validation error')
          }

          this.$store.commit('Cart/setChosenTender', {
            extraFields: {},
            tender: this.selectedTenderData,
          })

          // Don't need an e-wallet token for paypal
          await this.populateTransactions({
            tenderType: tender.type,
            tenderSource: tender.source,
            tenderData: {},
          })

          const paymentData = {
            // eslint-disable-next-line camelcase
            fee_amount: decimalFormat(this.chosenConvenienceFee),
            // eslint-disable-next-line camelcase
            paypal_brand: tender.source === 'direct' ? 'paypal' : tender.source,
          }
          if (this.shouldCollectDelivery) {
            const {
              address1,
              address2,
              city,
              state,
              postalCode,
              country,
            } = this.chosenAddress || {}
            paymentData.delivery = {
              name: tender.userName,
              address: [address1, address2].filter(address => address).join('\n'),
              city,
              state,
              zip: postalCode,
              country,
            }
          }

          if (this.extraFields.email && this.extraFields.phone) {
            paymentData.billing = {
              phone: this.extraFields.phone,
              email: this.extraFields.email,
            }
          }
          return api.createPaypalOrder(this.config.client, this.config.site, this.cart.id, paymentData)
        },

        // Used for the delayed PayPal workflow,
        // this function sets up the vaulting process for
        // the user to approve
        createVaultSetupToken: () => {
          if (!this.readyToSubmit() || !this.validateDelivery()) {
            throw new Error('Validation error')
          }

          return this.$store.dispatch('eWallet/createNewPayPalSetupToken', {
            clientDisplayName: this.clientTitle,
          })
        },

        // Once the user approves us to save the tender
        // for delayed PayPal from the vaultSetupToken, this
        // tender saves that tender in EWallet
        savePayPalTender: async (vaultSetupToken) => {
          const tenderData = await this.$store.dispatch('eWallet/saveNewPayPalTender', {
            setupToken: vaultSetupToken,
          })

          this.showConfirmation({ tender: tenderData, extraFields: this.extraFields })
        },

        pushConfirmationWithOrderID: async (data, actions) => {
          pushRouteWithState(this.$router, {
            name: 'confirmation',
            params: this.$route.params,
            state: {
              orderID: data.orderID,
            },
          })
        },

        onApprove: async (data, actions) => {
          // Set the payment response to be used to render the receipt.
          // Ideally, this onApprove handler would return the entire
          // response, but I (dbell) do not know the ramifications of
          // changing the onApprove API like that.
          payment = await api.approvePaypalOrder(
            this.config.client,
            this.config.site,
            this.cart.id,
            data.orderID,
            this.extraFields?.contactPreference,
            this.user.loggedIn,
          )
          payment.transactions = payment.transactions.map(this.createAndInflateTransaction)
          return payment.confirmation_number
        },

        success: async id => {
          EventBus.$emit('cart.checkout')

          // We do this check on the confirmation page as well.
          // This is for clients that use the checkout widget but
          // want to display their own receipt.
          if (this.config.checkoutWidget?.showReceipt === false) {
            this.$emit('payment-complete')
          }
          else if (this.urls.receipt) {
            this.$router.push({
              name: 'receipt-redirect',
              params: {
                ...this.$route.params,
                receiptId: id,
              },
            })
          }
          else {
            pushRouteWithState(this.$router, {
              name: 'receipt',
              params: {
                ...this.$route.params,
                receiptId: id,
              },
              state: {
                // The payment response comes from the onApprove callback
                payment,
                // TODO: this.chosenTender does not have enough data to render
                // anything useful in the receipt for the tender. Later,
                // when the Cart service returns the PayHist receipt, we
                // can use that information here.
                tender: this.chosenTender,
                showSurvey: true,
                urls: this.urls,
              },
            }).catch(error => {
              // Check to see if this is an informational error about how your
              // router push was redirected somewhere else. In this case, it's
              // because of the if block in the router beforeEach that adds site
              // to named routes. See:
              // https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
              // for more info. If not, throw the original error, otherwise
              // proceed as usual.
              if (!isNavigationFailure(error, Router.NavigationFailureType.redirected)) {
                throw error
              }
            })
          }

          await this.$store.dispatch('Cart/emptyCart')
          // We retire the old cart and load a new one. See action.
          await this.$store.dispatch('Cart/retireCart')
        },

        error: error => {
          if (error.message !== 'Validation error') {
            sentryException(error)
          }
          this.checkoutError = this.userFriendlyError(error)
        },
      }
    },

    displayEwallet () {
      return !this.useExternalCheckout
    },

    autoPaySelected () {
      return this.cart.enrollInAutopay
    },

    applePayConfig () {
      if (!this.allowedAlternativeTenderSources.includes('apple')) {
        return null
      }

      // TODO: PSC-20156 will absolutely obliterate this conditional block
      if (this.hasMultipleItemCategories && this.flags[`allow-mobile-pay-for-mds-carts.${this.config.client}-${this.config.site}`] !== true) {
        return null
      }

      // Disables Apple Pay where users are able to enroll for
      // autopay on a normal checkout

      const fee = this.$store.state.Cart.convenienceFees?.card
      // The label value is the vendor name that is displayed on the apple pay
      // modal
      return {
        total: { label: this.clientTitle || 'GovHub', amount: this.cart.total(fee) },
        supportedNetworks: this.allowedCardBrands,
        lineItems: [
          { amount: this.cart.subtotal, label: this.$t('subtotal.label') },
          { amount: fee, label: this.$t(`${this.$t('fee.label')}`) },
        ],

      }
    },

    // This config code could probably find a better home...
    //
    // This mostly follows Google Pay's documentation from here:
    // https://developers.google.com/pay/api/web/reference/request-objects#IsReadyToPayRequest
    //
    // Version 2.0 is the latest API version as of 07/28/2022
    googlePayConfig () {
      if (!this.allowedAlternativeTenderSources.includes('google')) {
        return null
      }

      // TODO: PSC-20156 will utterly destroy this conditional block
      if (this.hasMultipleItemCategories && this.flags[`allow-mobile-pay-for-mds-carts.${this.config.client}-${this.config.site}`] !== true) {
        return null
      }

      // Disables Google Pay when users are able to enroll for
      // autopay on a normal checkout

      // The Google Pay tender is unavailable when there are delayed payment
      // items in the cart.
      const fee = this.$store.state.Cart.convenienceFees?.card
      const total = decimalFormat(this.cart.total(fee))

      return {
        total,
        displayItems: [
          {
            label: this.$t('subtotal.label'),
            type: 'SUBTOTAL',
            price: decimalFormat(this.cart.subtotal),
          },
          {
            label: this.$t(`${this.$t('fee.label')}`),
            type: 'LINE_ITEM',
            price: decimalFormat(fee),
          },
        ],
        clientDisplayName: this.config.payHub.clientTitle,
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [{
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: this.allowedCardBrands.map(brand => brand.toUpperCase()),
            billingAddressRequired: true,
            billingAddressParameters: {
              format: 'FULL',
              phoneNumberRequired: true,
            },
          },
          tokenizationSpecification: {
            type: 'DIRECT',
            parameters: {
              protocolVersion: 'ECv2',
              publicKey: GSG_ENVIRONMENT === 'prod'
                ? 'BCxl8WqduREmLGm+LtoBHA5flw6PRrtwsbjn5KT3yEa/dbwqS319cPy8c/EMAqEy2YovlSSY64dWiAHJ/YlCP74='
                : 'BLb4N3jCkmZlIYS4F0DPuOqoxwn6pvDtUWSdRbDBZJG6mJc53/29B6E8LeT6V4fkrvUcdhwONbJGev+71vigHMI=',
            },
          },
        }],
      }
    },

    // Returns the address that E-Wallet will pre-fill into the billing address
    // fields. If there is only one item in the cart and that item has a
    // custom_parameters.address, that will be used. Otherwise the cart billing
    // address will be used.
    defaultBillingAddress () {
      if (this.items.length === 1) {
        const item = this.items[0]
        if (
          item.payable &&
          item.payable.customParameters &&
          item.payable.customParameters.address
        ) {
          return item.payable.customParameters.address
        }
      }
      return this.billing
    },

    // Tender types disabled due to payable.raw.payment_restrictions.
    // This is related to recent payments.
    restrictedPayableDisplayNamesByTenderTypes () {
      const restricted = {}

      for (const type of this.restrictedPayableMethods) {
        const payables = []
        for (const { payable } of this.items) {
          if (payable.isTenderRestricted(type)) {
            payables.push(`"${payable.displayName}"`)
          }
        }

        restricted[type] = {
          type,
          payables,
          methods: [
            type,
            // Always include alternate card sources when card is listed
            ...(type === 'card' && this.allowedAlternativeTenderSources.length ? this.allowedAlternativeTenderSources : []),
          ],
        }
      }

      return restricted
    },

    // Tender types disabled because a payable does not contain them in
    // payable.allowedTenderTypes. These are many-to-one so there is redundancy
    // in the payables lists.
    // {
    //   bank: {
    //     type: 'bank',
    //     payables: [
    //       'Transit Pass 1',
    //       'Transit Pass 3',
    //     ],
    //   },

    //   card: {
    //     type: 'card',
    //     payables: [
    //       'Transit Pass 1',
    //       'Transit Pass 3',
    //       'Transit Pass 5',
    //     ],
    //   },

    //   paypal: {
    //     type: 'paypal',
    //     payables: [
    //       'Transit Pass 1',
    //       'Transit Pass 2',
    //       'Transit Pass 3',
    //       'Transit Pass 4',
    //     ],
    //   },
    // }
    // XXX: Should this move into the store? We could compute it when
    // allowedPayableMethods is computed.
    disallowedPayableDisplayNamesByTenderTypes () {
      // Cache this in a variable and sort so there will always be a consistent
      // order. That's critical for avoiding redundant keys.
      const enabledTypes = this.siteEnabledPaymentMethods
      enabledTypes.sort()

      const byTypes = {}
      for (const type of enabledTypes) {
        if (this.allowedPayableMethods.includes(type)) {
          continue
        }

        const payables = []
        for (const { payable } of this.items) {
          // If there's just no restriction from this payable, then skip it
          if (payable.allowedTenderTypes.includes(type)) {
            continue
          }
          payables.push(`"${payable.displayName}"`)

          // TODO: PSC-13498 Refactor eWallet to distinguish between tender
          // sources and tender types (See cart store for explanation). Once
          // this is done we can change byTypes to byMethods and pass unique
          // messages for alternative tender sources.
          // if (type === 'card' && this.allowedAlternativeTenderSources.length) {
          //   // Always include alternate card sources when card is listed
          //   for (const source of this.allowedAlternativeTenderSources) {
          //     byMethods[source] = {
          //       method: source,
          //       payables: [],
          //     }
          //     byMethods[source].payables.push(displayName)
          //   }
          // }
        }

        byTypes[type] = {
          type,
          payables,
        }
      }

      return byTypes
    },

    disabledTendersMessage () {
      const messages = []
      const restrictedPayables = this.restrictedPayableDisplayNamesByTenderTypes
      const recognizedTypes = ['bank', 'card', 'paypal']

      for (const { type, payables, methods } of Object.values(restrictedPayables)) {
        // Only do this check for true tender types (not alternate sources).
        if (!recognizedTypes.includes(type)) {
          sentryException(new Error(`Unrecognized restricted payment method: ${type}. Users will still see a message indicating this, but it may leave something to be desired.`))
          messages.push(
            this.$t('cart.ewallet.tender_restrictions.unknown', {
              payables: truncatedTranslateOrList(payables),
              type,
            }),
          )
          continue
        }

        messages.push(
          this.$t('cart.ewallet.tender_restrictions.default', {
            payables: truncatedTranslateOrList(payables),
            method: translateOrList(methods.map(method => this.$t(`${method}.friendly_name.plural`))),
          }),
        )
      }

      return messages.join('\n')
    },

    disabledTendersTooltips () {
      const tooltips = {}
      const restrictedPayables = this.restrictedPayableDisplayNamesByTenderTypes
      const disallowedPayables = this.disallowedPayableDisplayNamesByTenderTypes

      for (const { type, payables } of Object.values(restrictedPayables)) {
        tooltips[type] = this.$t('cart.ewallet.tender_restrictions.tooltip', {
          payables: truncatedTranslateOrList(payables),
        })
      }

      for (const { type, payables } of Object.values(disallowedPayables)) {
        tooltips[type] = this.$t('cart.ewallet.disabled_tenders.tooltip', {
          type: this.$t(`${type}.friendly_name.default`),
          payables: truncatedTranslateOrList(payables),
        })
      }

      return tooltips
    },

    // Pulls site settings on whether to render two-pass or one-pass authorization statement for bank e-check payments
    // returns a boolean on whether the site setting exists and is not an empty string (boolean on if the setting is two-pass)
    isTwoPassAuthorization () {
      const itemCategoriesArray = this.config.cart?.itemCategories

      // iterate through the itemCategories site settings and if any of the items have a property "feeStatementDescriptionBanks" with a valid value (non empty string) return true
      for (const itemCategory of itemCategoriesArray) {
        if (itemCategory.feeStatementDescriptionBanks && itemCategory.feeStatementDescriptionBanks.length > 0) {
          return true
        }
      }
      return false
    },

    forceSaveMethod () {
      return this.cart.enrollInAutopay
    },

    shouldCollectDelivery () {
      return this.useDelivery && this.cart.needsDelivery
    },

    enableRExHubPickUp () {
      return this.config.delivery?.enableRExHubPickUp
    },

    enablePermanentAddressChange () {
      return this.config.delivery?.enableRExHubPermanentAddressChange
    },

    // This boolean is for insurance affidavit renewals by military personnel
    // stationed outside of Florida. They are required to provide a shipping
    // address regardless of whether or not the county accepts address changes
    requirePermanentAddressChange () {
      return this.items.some((item) => {
        return item.payable.configDisplayType.name === 'rex-vehicle-registration' &&
          item.payable?.customParameters?.insurance?.military_status === 'floridian_stationed_outside_florida'
      })
    },

    usesRenewExpress () {
      return this.config?.renewexpress?.meta?.enabled
    },

    hasRExItems () {
      return this.items.some((item) => {
        return item.payable.configDisplayType.name === 'rex-vehicle-registration'
      })
    },

    hasTollItems () {
      return this.items.some((item) => {
        return item.payable.path.search('/Taxsys-TollViolations') > -1
      })
    },

    isMixedRExCart () {
      return this.hasRExItems && this.cart.hasImmediateItems
    },

    rexPayablesAdaptor () {
      const rexItem = this.items.find((item) => {
        return item.payable.configDisplayType.name === 'rex-vehicle-registration'
      })
      if (!rexItem) {
        return null
      }
      return `/${rexItem.payable.raw.adaptor}/v0`
    },

    // Only use these when necessary and don't conflate them with the numeric
    // fees
    displayFormattedFees () {
      return Object.keys(this.convenienceFees).reduce((fees, method) => {
        fees[method] = `$${displayFormat(this.convenienceFees[method])}`
        return fees
      }, {})
    },

    // The same logic here is used for
    // alwaysGetWarehouseToken and alwaysGetWarehouseTokenForBanks
    shouldAuthorizeTendersBeforeCheckout () {
      // Currently we are not tokenizing for Apple Payments
      // due to issues between Apple/Gogole Pay and World Pay
      // that prevent $0 authorization from producing reusable tokens
      return this.shouldSaveTenders && this.user.loggedIn &&
        this.selectedTenderFormType !== 'apple' && this.selectedTenderFormType !== 'google'
    },

    shouldSaveTenders () {
      // The useVaultToken site setting only applies to cards, other
      // tenders that use this setting should still be saved
      return !(this.config.cart.useVaultToken && this.selectedTenderFormType === 'card')
    },

    hideFeeMessage () {
      return this.useCostLinkedPricing
    },

    /**
     * Returns true if the user is allowed to enroll this cart in
     * autopay. Users are allowed to enroll in autopay if the SchedPay
     * module is enabled and the "X Days Before Due" frequency is not
     * disabled (since that is the type of scheduled payment that is
     * created on the Confirmation screen).
     */
    canEnrollInAutopay () {
      // Reaching in to schedpay config manually so we don't have to
      // have schedpay's store loaded
      return this.useSchedPay && !this.config.schedPay?.userDisabledFrequencies?.includes('beforeDue')
    },

    /**
     * Returns true if the current selected tender can be used for schep
     * automatic payments.
     */
    selectedTenderSupportsAutopay () {
      // If the user has not yet selected a tender type, they will be allowed
      // to enable autopay
      return ['', 'card', 'bank'].includes(this.selectedTenderFormType)
    },

    ...mapGetters('Cart', [
      'urls',
      'cartLoadPromise',
      'billing',
      'cart',
      'items',
      'merchantIDs',
      'chosenConvenienceFee',
      'restrictedPayableMethods',
      'allowedPayableMethods',
      'siteEnabledPaymentMethods',
      'allowedAlternativeTenderSources',
      'prepopulateCartWarnings',
      'chosenTender',
      'deliveryOption',
      'pickUpInfo',
      'cartItemsAllowPickUp',
      'itemPayables',
      'disabledCardBrands',
      'hasMultipleItemCategories',
      'createAndInflateTransaction',
      'deliveryOption',
      'selectedDeliveryMethod',
    ]),

    ...mapState('Cart', [
      'chosenAddress',
      'convenienceFees',
      'changeAddress',
      'showSaveCheckbox',
      'autoenrollRenewals',
    ]),

    ...mapGetters('PayHub', [
      'translatedFeeKeys',
      'clientTitle',
    ]),

    ...mapState('eWallet', [
      'jwtFailure',
      'loadPromise',
    ]),

    ...mapConfigGetters([
      'useDelivery',
      'useRenewExpress',
      'useSchedPay',
      'useExternalCheckout',
      'useCostLinkedPricing',
    ]),

    ...mapConfigState([
      'config',
      'flags',
    ]),
  },

  watch: {
    'cart.subtotal': function () {
      if (!this.$refs.ewallet) {
        return
      }
      // This should really be safe to do,
      // because per the thread at https://grantstreet.slack.com/archives/C02MF0UU6LR/p1710273829035319
      // the presence of this.$refs.ewallet (eWalletWrapper.vue)
      // should imply that its child $refs.ewallet (eWallet.vue) must have loaded
      // but it's missing its methods! (and not $refs)
      // We *suspect* this has to do with error handling and/or component lifecycle
      // but could not find any evidence
      // This apparently only happens on county-taxes.net sites
      // which as of writing suggests a problem with Vehicle Registration Renewals
      // (or maybe those are just most likely to change amounts on the checkout page)
      try {
        this.$refs.ewallet.clearFeeCheckbox()
      }
      catch (error) {
        sentryException(`Unexpectedly unable to clear fee agreement checkbox for cart ${this.cart.id}, see also PSC-18624:\n${error}`)
      }
    },
    jwtFailure (failed) {
      if (failed) {
        this.forceHideReminderCheckbox = true
        return
      }
      this.forceHideReminderCheckbox = false
    },
    async 'cart.items' () {
      // If there are toll items in the cart, perform checks to make sure
      // they each have a renewal buddy to be checked out with. If not, remove the toll item.
      if (this.hasTollItems) {
        await this.manageTollItems()
      }
    },
  },

  beforeMount () {
    // Show the expiration warning if we already know the cart is expired.
    // Note: this.cart is only defined at this point for sites with pages other
    // than the check-out page. On checkout-only sites (i.e., standalone
    // redirects), the cart hasn't yet been loaded, and the Cart model will
    // trigger via the event bus as necessary once it _is_ loaded.
    if (this.cart.minutesUntilExpiration !== null) {
      this.setExpirationMessage(this.cart.minutesUntilExpiration, false)
    }

    // Don't "remember" previously checked items; it's more intuitive UX-wise if
    // the checkboxes reset.
    this.$store.commit('Cart/resetConfirmedItemIds')

    // Register EventBus listeners ahead of time
    EventBus.$on('ewallet.tenderMgr.includeContact', this.includeContactListener)
    EventBus.$on('ewallet.tenderMgr.showCheckbox', this.showCheckboxListener)

    // TODO: PSC-20124 - This needs to be left "on" after the component is
    // destroyed or we run into checkout errors. That's some kind of bug, at
    // least, in the way we handle the event/update. We shouldn't be leaving
    // multiple live listeners for this.
    EventBus.$on('cart.replaced', jwt => useEWalletHelpers().updateAndRerenderEWallet(jwt))

    // Schedule messages for expiring carts
    EventBus.$on('cart.expiresIn', this.setExpirationMessage)
    EventBus.$on('cart.expired', this.setExpirationMessage)

    // For allowing items in the cart to be modified
    EventBus.$on('payable.change-payable', this.receivePendingPayableChange)
  },

  async mounted () {
    // Ensure E-Wallet submission is enabled
    this.$wait.end('submitting')

    // Lazy-load E-Wallet when we first reach the cart page
    try {
      await this.cartLoadPromise
      await this.loadPromise
    }
    catch (error) {
      console.error('Could not initialize cart:', error)

      if (error.response.status === 429) {
        this.$router.replace({
          name: 'rate-limit-error',
          params: this.$route.params,
        })
      }
      else {
      // Redirect to error page (formerly Base.redirectToErrorPage)
        this.$router.replace({
          name: this.isRedirect ? 'redirect-error' : 'cart-error',
          params: this.$route.params,
        })

        this.initFailure = true
      }
    }
    finally {
      if (this.tenderValidationError) {
        if (this.tenderTypeForValidationError === 'bank') {
          this.$refs.ewallet.setBankError(this.tenderValidationError)
        }
        else {
          // better to default to card then not show the error at all
          this.$refs.ewallet.setCardError(this.tenderValidationError)
        }
      }
    }

    this.$store.commit('Cart/setCheckoutNavSteps', [
      {
        name: 'cart',
        title: 'checkout.cart.step',
        icon: 'progress-bar-shopping-cart',
      },
      {
        name: 'confirmation',
        title: 'checkout.review.submit.step',
        icon: 'progress-bar-double-check',
      },
      {
        name: 'receipt',
        title: 'checkout.receipt.step',
        icon: 'progress-bar-receipt',
      },
    ])

    // If there are toll items in the cart, perform checks to make sure
    // they each have a renewal buddy to be checked out with. If not, remove the toll item.
    if (this.hasTollItems) {
      await this.manageTollItems()
    }

    // Reset the selected delivery option depending on the configs enabled
    if (this.shouldCollectDelivery) {
      const isShippingOnly = !this.useDelivery || !this.enableRExHubPickUp || !this.cartItemsAllowPickUp
      const chargeMailFee = this.config.renewexpress?.renewalServiceFee.includes('chargeMailFee')
      const chargePickUpFee = this.config.renewexpress?.renewalServiceFee.includes('chargePickUpFee')
      const defaultDeliveryOption = isShippingOnly || (chargeMailFee && chargePickUpFee) ? 'shipping' : 'none'
      await this.$store.commit('Cart/setDeliveryOption', defaultDeliveryOption)
      await this.setChangeAddress(false)
      await this.switchDeliveryOption(defaultDeliveryOption, isShippingOnly)
      this.setRequiredPermanentAddressChange(false)
    }

    // If an item in the cart supports yearly autopay, and the user has not yet
    // been shown the renewal autopay modal, display the modal now.
    if (this.useSchedPay && this.autoenrollRenewals === null && this.cart.items.find(item => item.payable.isYearlyAutopayEligible)) {
      const existingSchedules = await Promise.all(
        this.cart.items
          .filter(item => item.payable.isYearlyAutopayEligible)
          .map(item => this.checkForExistingSchedules(item.payable.savePath)),
      )
      // If any renewal in the cart does not have an existing schedule, display
      // the renewal autopay modal
      if (existingSchedules.includes(false)) {
        this.$bvModal.show('renewalAutopayModal')
      }
    }
  },

  beforeUnmount () {
    // Don't forget to remove handlers
    EventBus.$off('ewallet.tenderMgr.includeContact', this.includeContactListener)
    EventBus.$off('ewallet.tenderMgr.showCheckbox', this.showCheckboxListener)
    EventBus.$off('cart.expiresIn', this.setExpirationMessage)
    EventBus.$off('cart.expired', this.setExpirationMessage)
    EventBus.$off('payable.change-payable', this.receivePendingPayableChange)
  },

  methods: {
    ...mapActions('Cart', [
      'inflateAndUpdateCart',
      'updateDBCart',
    ]),

    // manageTollItems is called on mount for the checkout component when there are toll items in the cart.
    // This functionality prevents a customer from removing a renewal from the mini cart and only checking out the
    // toll item.
    async manageTollItems () {
      // Remove orphaned toll items
      const tollsInCart = this.items.filter(item => item.payable.path.search('/Taxsys-TollViolations') > -1)
      const renewalsInCart = this.items.filter(item => item.payable.path.search('type=renewal') > -1)

      // For each toll that we found, make sure it has a renewal buddy
      for (const item of tollsInCart) {
        const tollPlate = item.payable.raw.cachesafe_search_pairs.license_plate

        const renewalItem = renewalsInCart.find((renewal) => {
          return tollPlate === renewal?.payable?.raw?.cachesafe_search_pairs?.license_plate
        })

        // If there isn't a renewal buddy, remove the toll item from the cart.
        if (!renewalItem) {
          await this.removeFromCart(item)
        }
      }
    },

    includeContactListener (includeContact) {
      this.shouldIncludeContact = includeContact
    },

    showCheckboxListener (showCheckbox) {
      this.shouldShowCheckbox = showCheckbox
    },

    setExpirationMessage (minutes, autoScroll = true) {
      // Handles falsy values from cart.expired
      if (!minutes || minutes <= 0) {
        // PSC-11977: Log details when carts are expired, since we're getting
        // reports of incorrect expiration.
        this.$store.dispatch('PayHub/logDiagnostics', {
          event: 'cartExpired',
          cartId: this.cart.id,
          cartExpiration: this.cart.expiration,
          browserTime: new Date().toISOString(),
        })

        this.expirationWarning = this.$t('cart.expired.default')
        // If the message is new scroll to it
        if (autoScroll) {
          this.scrollTo('.cart-expiration-alert')
        }
        return
      }
      if (minutes <= 10) {
        // If the message is new scroll to it
        if (autoScroll && !this.expirationWarning) {
          this.scrollTo('.cart-expiration-alert')
        }
        this.expirationWarning = this.$t('cart.expires', { minutes: Math.round(minutes) })
        return
      }
      this.expirationWarning = null
    },

    readyToSubmit () {
      const tenderValid = this.$refs.ewallet.validate()
      let deliveryValid = true
      if (this.shouldCollectDelivery) {
        deliveryValid = this.$refs.delivery.validate()
      }
      return (tenderValid && deliveryValid)
    },

    async saveChosenAddress (chosenAddress) {
      if (this.shouldCollectDelivery) {
        this.$store.commit('Cart/setChosenAddress', chosenAddress)
      }
      else {
        this.$store.commit('Cart/clearChosenAddress')
      }
    },

    async submitCart (chosenAddress) {
      if (this.$refs.ewallet) {
        this.$refs.ewallet.clearApplePayError()
        this.$refs.ewallet.clearGooglePayError()
      }

      if (this.selectedTenderFormType === 'google') {
        // Sets the billing address info from Google Pay's API back to our tender
        Object.assign(this.selectedTenderData.billingAddress, chosenAddress)
        await this.$store.commit('Cart/setBillingAddress', chosenAddress.billingAddress)
      }

      if (this.readyToSubmit()) {
        if (this.shouldCollectDelivery) {
          if (chosenAddress) {
            this.$store.commit('Cart/setChosenAddress', chosenAddress)
          }
        }
        else {
          this.$store.commit('Cart/clearChosenAddress')
        }

        this.$refs.ewallet.submit()
      }
    },

    // We need to provide a default value here, since feeResponse won't always be provided by E-Wallet's submitCallback.
    // Do we need to change any E-Wallet docs to clarify this?
    async showConfirmation ({ tender, extraFields, feeResponse = {} }) {
      // Save payment data for later
      this.paymentData = { extraFields, tender }

      // Make sure to check that schedpay is enabled
      if (this.canEnrollInAutopay && this.cart.enrollInAutopay) {
        // Group the payables being enrolled in autopay by common terms. (Yearly
        // on date agreements will be different per date, which is also
        // different from the regular autopay which pays 4 days before due)
        this.termsData = GroupPayablesWithFrequencies(
          this.cart.items.filter(i => i.enrollInAutopay)
            .map(i => i.payable),
        )

        // Don't show the spinner on the button while the modal is open.
        // TODO: We should probably reconsider interfering with EWs lifecycle
        // like this. We check the wait flag and use it for more than just the
        // spinner. From inside TenderManager, without knowing about this 👇 it
        // would seem fairly sensible to assume that the flag wouldn't be
        // surreptitiously toggled twice like we do here.
        this.$wait.end('submitting')

        // Give the v-if time to kick in
        this.$nextTick(() => this.$bvModal.show('autopay-terms'))
        return
      }

      await this.updateRExhubDeliveryMetadata()

      await this.saveTenderAndSubmit({ tender, extraFields, feeResponse })
    },

    async updateRExhubDeliveryMetadata () {
      if (!this.hasRExItems) return

      // Create a new cart for adding metadata (cart custom event details)
      let newCart = (await this.$store.dispatch('Cart/getCart', this.cart.id))?.data
      newCart.metadata = {
        ...newCart.metadata,
        rexReceiveReminders: String(this.rexReceiveReminders),
      }

      // Flag the permanent address change when address changes are allowed/required
      // and the user has selected a new address (neither the on file address
      // nor the pick up option)
      if ((this.enablePermanentAddressChange || this.requirePermanentAddressChange) &&
          this.deliveryOption !== 'none' &&
          this.deliveryOption !== 'pickUp' &&
          this.selectedDeliveryOption !== 'onFile') {
        newCart.metadata.rexAddressChange = this.chosenAddress?.addressType || ''
        this.setRequiredPermanentAddressChange(this.requirePermanentAddressChange)
      }

      // With RExHub renewals enabled, these are the possible outcomes:
      // 1. Pick up enabled, pick up selected
      //    -> Update metadata with pick up info
      // 2. Pick up enabled, shipping selected
      //    -> Clear pick up-specific metadata
      // 3. Pick up enabled, address on file
      //    -> Clear pick up-specific metadata
      // 4. Pick up not enabled
      //    -> Clear pick up-specific metadata
      // 5. RExHub not enabled
      //    -> Don't do anything
      if (this.enableRExHubPickUp && this.deliveryOption === 'pickUp') {
        newCart = await this.updateCartDeliveryMetadata(newCart, this.paymentData?.tender, this.paymentData?.extraFields)
      }
      else if (this.usesRenewExpress) {
        newCart = await this.clearCartDeliveryMetadata(newCart)
      }

      // Updates remote and local cart with new metadata
      // XXX: Always inflate when you do a DB update.
      await this.inflateAndUpdateCart((await this.updateDBCart({ cartId: this.cart.id, cart: newCart }))?.data)
    },

    async submitWithTerms (terms) {
      const time = new Date().toISOString()

      // Save the agreement to each schedule
      for (let i = 0; i < terms.length; i++) {
        // Iterate over each of the agred terms
        this.termsData[i].payables.forEach(payable => {
          // Find all of the cart items that were enrolled in autopay with those
          // terms, and store the terms on the item.
          this.cart.items.forEach(item => {
            if (item.enrollInAutopay && item.payable.path === payable.path) {
              item.scheduledPaymentAgreement = {
                terms: terms[i],
                time,
              }
            }
          })
        })
      }

      // Show the spinner while submission finishes
      this.$wait.start('submitting')

      // Update the cart with delivery method metadata
      await this.updateRExhubDeliveryMetadata()

      // Complete submission
      this.saveTenderAndSubmit()
    },

    async saveTenderAndSubmit ({ tender, extraFields, feeResponse: { transactions, totals } = {} } = this.paymentData) {
      // XXX: I'm not sure why the clone is necessary here. *Something*
      //      is resetting the value while rendering the confirmation
      //      page.
      this.$store.commit('Cart/setChosenTender', { tender, extraFields: cloneDeep(extraFields) })

      // We let cart store the bank account number when there's a delayed $0 auth payment
      this.$store.commit('Cart/setBankAccountNumber', this.selectedTenderData.bankAccountNumber)

      // EWallet will pass back the list of transactions that we fetch in
      // getUpdatedFee. In that case we know that we don't need to fetch them a
      // second time so we set them in the store.
      // They're not set earlier so that they aren't populated globally before
      // final submission. (The user can exit the submission process directly
      // after getUpdatedFee).
      if (transactions && totals) {
        this.$store.commit('Cart/setTransactions', transactions)
        this.$store.commit('Cart/setTotals', totals)
      }
      else {
        const transactionsData = (tender.type === 'bank' && !this.user.loggedIn)
          ? { bankAccountNumber: this.selectedTenderData.bankAccountNumber, bankAccountType: tender.bankAccountType }
          : { ewalletToken: tender.ewalletToken }
        await this.populateTransactions({
          tenderType: tender.type,
          tenderSource: tender.source,
          tenderData: transactionsData,
        })
      }

      this.$store.dispatch('PayHub/setUserProfile', this.profileChanges)

      if (['apple', 'google'].includes(tender.source) && !this.useCostLinkedPricing) {
        this.checkOutWithoutConfirmation({
          tender: this.chosenTender,
          extraFields,
          address: this.chosenAddress,
        })
      }
      else {
        this.$router.push({
          name: 'confirmation',
          params: {
            ...this.$route.params,
          },
        })
      }

      this.$wait.end('submitting')
    },

    async beforeEwalletSubmit ({ tender } = {}) {
      return this.validatePayables(tender) && await this.validateDelivery()
    },

    updateSelectedTenderData (data) {
      this.selectedTenderData = data
    },

    // This does a little more than just validation. If the user has not yet
    // confirmed their address, this gets Delivery Method to show that modal.
    async validateDelivery () {
      if (!this.shouldCollectDelivery) {
        return true
      }

      // Workflow for RExHub-enabled sites
      if (this.usesRenewExpress) {
        const selectedDeliveryOption = this.$refs.delivery.getSelectedDeliveryOption()
        this.setDeliveryOption()

        // Address on file option is selected
        if (selectedDeliveryOption === 'onFile') {
          this.$refs.delivery.setLastSelected(selectedDeliveryOption)
          return true
        }
        // Pick up option is selected
        else if (selectedDeliveryOption === 'pickUp') {
          this.setPickUpInfo()
          return this.$refs.delivery.validate()
        }
      }

      // If the user selected their billing address (pulled from E-Wallet), it
      // does not match the Lob suggestion, and they have not yet confirmed the
      // suggestion modal, this will show that modal.
      return (
        this.$refs.delivery.validate() &&
        await this.$refs.delivery.verifySelectedAddress()
      )
    },

    validatePayables (tender) {
      if (this.$refs.cart.validate(tender)) {
        return true
      }
      this.$refs.cart.scrollToFirstError()
      return false
    },

    async getUpdatedFee (tender) {
      // Maybe someday we'll want to check fees for non-cards
      if (tender.type !== 'card') {
        return {}
      }

      //! Don't populate transactions here. Otherwise bad transactions could be
      //! left in the store if the user exits submission.
      const response = await this.fetchTransactions({
        tenderType: tender.type,
        tenderSource: tender.source,
        tenderData: { ewalletToken: tender.ewalletToken },
      })

      return {
        fee: displayFormat(parseNumber(response.totals.immediateFeeTotal) + parseNumber(response.totals.delayedFeeTotal)),
        response,
      }
    },

    async receivePendingPayableChange (payableChange) {
      // Prevents repeat triggers of the same event
      if (this.payablesEvents[payableChange.eventId]) {
        return
      }
      else {
        this.payablesEvents[payableChange.eventId] = true
      }

      // Show the cart modal while we wait to receive any other payable changes.
      if (!this.showUpdateCartModal) {
        this.showCartModal(this.$t('checkout.change_payable.message'))
      }

      // Push the payable change onto the queued pending changes
      this.pendingPayableChanges.push(payableChange)
      // If we already have a timeout identifier, other payable changes were
      // received before this change. All of the pending changes will be
      // processed by the existing timeout, so there's nothing left to do here.
      if (this.payableChangeTimeout !== 0) return
      // Wait 1/2 second before changing all of the payables in the cart
      this.payableChangeTimeout = setTimeout(this.changePayables, 500)
    },

    async changePayables () {
      const revertCallbacks = []
      const inProgressMessage = this.$t('checkout.change_payable.message')
      await this.safeUpdateCart(inProgressMessage, async () => {
        // Update the cart items and set the local cart
        try {
          let updatedItems = this.cart.items

          while (this.pendingPayableChanges.length > 0) {
            const { from, to, undo } = this.pendingPayableChanges.shift()
            // Store the undo function if we need to rollback the changes
            revertCallbacks.push(undo)

            // Find the index of the item we want to switch
            const index = updatedItems.findIndex(item => item.payable.raw.path === from.path)
            // Get all the items before and after the target item in order to maintain item order
            const itemsBefore = updatedItems.slice(0, index)
            const itemsAfter = updatedItems.slice(index + 1)

            updatedItems = [
              ...itemsBefore,
              {
                // Keep original item properties
                ...updatedItems[index],
                // Allow new payable to override
                ...to.raw,
              },
              ...itemsAfter,
            ]
          }

          // Update the cart
          const cart = (await this.$store.dispatch('Cart/getCart', this.cart.id))?.data
          cart.items = updatedItems
          // XXX: Always inflate when you do a DB update.
          await this.inflateAndUpdateCart((await this.updateDBCart({ cartId: this.cart.id, cart }))?.data)
        }
        catch (error) {
          sentryException(error)
          revertCallbacks.forEach(undo => undo(error))
          return this.$t('checkout.change_payable.error')
        }
        finally {
          // Not really necessary but clearing this makes me feel better lol
          clearTimeout(this.payableChangeTimeout)
          // Pending payable changes were received while the cart was being
          // updated. We'll wait half a second before processing these changes.
          if (this.pendingPayableChanges.length > 0) {
            this.payableChangeTimeout = setTimeout(this.changePayables, 500)
          }
          else {
          // There are no more payable changes.
            this.payableChangeTimeout = 0
          }
        }
      })
    },

    async setDeliveryOption () {
      const method = this.$refs.delivery.getSelectedDeliveryOption()
      const option = method === 'pickUp' || method === 'none' ? method : 'shipping'
      await this.$store.commit('Cart/setSelectedDeliveryMethod', method)
      await this.$store.commit('Cart/setDeliveryOption', option)
    },

    async switchDeliveryOption (option, updateShowDeliveryOptionDescription = true) {
      const inProgressMessage = this.$t('checkout.change_delivery_option.message')
      const curDeliveryOption = await this.getCartRenewalType() || 'none'
      await this.safeUpdateCart(inProgressMessage, async () => {
        try {
          this.$store.commit('Cart/setDeliveryOption', option)
          await this.switchRenewalPayables(option)
          this.showDeliveryOptionDescription = updateShowDeliveryOptionDescription && true
        }
        catch (error) {
          sentryException(error)
          this.$store.commit('Cart/setDeliveryOption', curDeliveryOption)
          this.$refs.delivery.setSelectedDeliveryTab(curDeliveryOption)
          return this.$t('checkout.change_delivery_option.error')
        }
      })
    },

    async getCurrentPayableDeliveryType () {
      return await this.$store.dispatch('Cart/getCartRenewalType')
    },

    async switchRenewalPayables (option) {
      if (!this.hasRExItems) {
        return
      }

      if (option === await this.getCurrentPayableDeliveryType()) {
        return
      }

      if (option === '') {
        option = 'none'
      }

      const cart = await this.$store.dispatch('Cart/createCartWithPickUpFlag', {
        cart: this.cart,
        delivery: option,
      })

      this.inflateAndUpdateCart(cart)
    },

    async setPickUpInfo () {
      const pickUpInfo = this.$refs.delivery.getPickUpInfo()
      await this.$store.commit('Cart/setPickUpInfo', pickUpInfo)
    },

    async clearCartDeliveryMetadata (cart) {
      // This is a better way to remove props than the delete operator.
      // See psc-js/utils/objects.js for more.
      const {
        // Disallowed keys
        'postback_url': deletedPostbackUrl,
        'deliveryMethod': deletedDeliveryMethod,
        'gsgFee': deletedGsgFee,
        'userPhone': deletedUserPhone,
        'userEmail': deletedUserEmail,
        'pickupPersonName': deletedPickupPersonName,
        'pickupLocationID': deletedPickupLocationId,
        // What we want to keep
        ...sanitized
      } = cart.metadata
      cart.metadata = sanitized

      return cart
    },

    async updateCartDeliveryMetadata (targetCart, tender, extraFields) {
      const cart = targetCart || await this.getCart()
      const metadata = {
        // eslint-disable-next-line camelcase
        postback_url: this.urls?.postback || '',
        deliveryMethod: 'PU',
        gsgFee: this.displayFormattedFees[tender.type],
        userPhone: extraFields?.phone,
        userEmail: extraFields?.email,
        pickupPersonName: this.pickUpInfo.fullDetails,
        pickupLocationID: String(this.pickUpInfo.locationId),
      }

      cart.metadata = {
        ...cart.metadata,
        ...metadata,
      }

      return cart
    },

    async checkCartDeliveryOption () {
      if (this.enableRExHubPickUp && this.hasRExItems) {
        const deliveryOption = await this.$store.commit('Cart/getCartRenewalType')
        await this.$store.commit('Cart/setDeliveryOption', deliveryOption)
        EventBus.$emit('delivery.setOption', deliveryOption)
      }
    },

    showCartModal (inProgressMessage) {
      if (this.showUpdateCartModal) return
      this.updateCartModalMessage = inProgressMessage
      this.updateCartModalError = ''
      this.showUpdateCartModal = true
    },

    async safeUpdateCart (inProgressMessage, updateCartFunction) {
      // Show modal to block other actions that may create race conditions
      this.showCartModal(inProgressMessage)

      // Do the work
      const error = await updateCartFunction()

      if (error) {
        this.updateCartModalError = error
        return
      }
      // Close modal now that we're done
      this.showUpdateCartModal = false
    },

    async setChangeAddress (changeAddress) {
      this.$store.commit('Cart/setChangeAddress', changeAddress)
    },

    handleError (error) {
      this.checkoutError = this.userFriendlyError(error)
    },

    checkOutWithoutConfirmation ({ tender, extraFields, address }) {
      return this.checkOut({
        context: this,
        tender,
        extraFields,
        address,
        user: this.user,
        addAddress,
        updateLastUsedAddress,
        resetTempAddresses,
        sentryException,
        eventBus: EventBus,
      })
    },

    ...mapActions('Cart', [
      'setPrepopulateCartWarnings',
      'getCartRenewalType',
      'populateTransactions',
      'fetchTransactions',
      'addToCart',
      'removeFromCart',
      'autoenrollRenewalsInAutopay',
    ]),
    ...mapActions('SchedPay', [
      'checkForExistingSchedules',
    ]),

    ...mapMutations('Cart', [
      'setRequiredPermanentAddressChange',
    ]),

    renewalAutopayModalHidden () {
      this.autoenrollRenewalsInAutopay(false)
    },

    acceptRenewalAutopayModal () {
      this.autoenrollRenewalsInAutopay(true)
    },
  },
}
</script>
