import { reorderByPropIndex } from '@grantstreet/psc-js/utils/sort.js'
import { handleDynamicImport } from './dynamic-import-helpers.ts'
import type BulkUploadConfig from './models/bulk-add-to-cart/BulkUploadConfig.ts'
import { getDynamicImportHandler } from '@grantstreet/psc-vue/utils/dynamic-imports.ts'

// The Payables backend doesn't guarantee that payables come back in the
// order they were searched. Make sure that we return them in that order
// though
export const orderPayablesByPaths = ({ payables, orderedPaths }) => {
  if (orderedPaths[0] && orderedPaths[0][0] !== '/') {
    throw new TypeError('Invalid payable paths')
  }
  return reorderByPropIndex(payables, orderedPaths, 'savePath')
}

// Some payable paths may include reserved URL characters such as '?'. Use this method to encode
// the path so that it can be treated as a single term by GET requests. This prevents things
// like strings after a '?' being consumed by the endpoint as query parameters instead of being
// used by the receiving adaptor to find/create the payable.
// This function also strips the leading '/' if it finds one as that is not part of the path but
// rather a delimiter between the path of the request endpoint and a Payable's path.
export const encodePayablePath = (path) => {
  if (path[0] === '/') {
    path = path.substring(1)
  }
  return encodeURIComponent(path)
}

/**
 * This getBulkAddToCartFileFormat function allows us to import the correct bulk
 * file format at runtime. This way, we don't need to import every single known
 * TypeScript file format all at once.
 *
 * @param {string} configName The name of the config to import. This will be
 *                            the value from Site Settings and will be mapped
 *                            to the TypeScript file that needs to be imported
 *                            (E.g., "fl" => "Florida" => Florida.ts).
 * @returns {object} An initialized file format object containing everything
 *                   needed to parse a client-specific CSV file.
 */
export const getBulkAddToCartFileFormat = async (configName) => {
  // Dynamic imports require some part of the path to be explicitly laid out
  // in the string so that Webpack knows where and what to look for. This
  // particular bit below works, but putting the entire string in a variable
  // does not.
  //
  // See here for an additional explanation:
  // https://javascript.plainenglish.io/how-webpack-handles-dynamic-imports-with-variable-paths-9c598a2aa4e3
  //
  let moduleName
  switch (configName) {
  case 'ca-sbc':
    moduleName = 'CA-SanBernardino'
    break
  case 'ca-sac':
    moduleName = 'CA-Sacramento'
    break
  case 'fl':
    moduleName = 'Florida'
    break
  case 'ca':
    moduleName = 'California'
    break
  case 'sunny':
    moduleName = 'Sunshine'
    break
  }

  const fetchConfigClass = getDynamicImportHandler(
    () => handleDynamicImport<{ default: typeof BulkUploadConfig }>(
      `./models/bulk-add-to-cart/formats/${moduleName}`,
      import(`./models/bulk-add-to-cart/formats/${moduleName}`),
    ),
  )
  const ConfigClass = (await fetchConfigClass()).default
  return new ConfigClass()
}

// Since new site setting options (ca-sbc, ca-sac) were added for the Bulk Add File Format setting
// we need to adjust the logic in what exactly we send to the backend for Bulk Search. Previously, the
// File Format site setting was sent as the SearchFormat, this function is to ensure that the state abbrev
// is sent as the SearchFormat instead of the File Format setting value.
export const assignSearchFormat = (configName) => {
  switch (configName) {
  case 'ca-sbc':
    return 'ca'
  case 'ca-sac':
    return 'ca'
  case 'fl':
    return 'fl'
  case 'ca':
    return 'ca'
  case 'sunny':
    return 'sunny'
  default:
    return ''
  }
}
